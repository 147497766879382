import { useMediaQuery } from "react-responsive";
import { StaticImage } from "gatsby-plugin-image";
import {
  container,
  eventDate,
  eventTitle,
  eventDesc,
  siteTitle,
  menuItem,
  images,
} from "../layout.module.css";
import "../sidebar.css";
import Header from "./header";
import Sidebar from "./sidebar";
import {
  Button,
  TimePicker,
  Input,
  InputLabel,
  FormControl,
} from "@mui/material";
import React, { useRef } from "react";

const MobileContent = () => {
  return (
    <div>
      <div className={menuItem} id="outer-container">
        <Sidebar
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        />
      </div>
      <p className={siteTitle}>Hotel & Travel</p>
    </div>
  );
};

const TravelPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  return (
    <div className={container}>
      <div>
        {isTabletOrMobile && MobileContent()}
        {/* { MobileContent() } */}
      </div>
      <Header></Header>
      <p className={eventDate}>Vincci EverEden Beach Resort</p>
      <p className={eventDesc}>
        <div>
          Vincci EverEden is located in the Athenian Riviera 30-35
          minutes from the Athens International Airport and 1 hour from the city
          center. To book your stay for our wedding, you can use the{" "}
          <strong style={{ fontWeight: "bold" }}>
            VineetandPriyankaWedding
          </strong>{" "}
          booking code at this{" "}
          <a href="https://en.vinccievereden.com/bookcore/availability/rooms/evereden?cp=VineetandPriyankaWedding&adults=2&babies=0&checkin=2025-05-25&checkout=2025-05-28">
            link
          </a>{" "}
          for a minimum 15% discount between May 24-29, 2025 which can be
          combined with other offers. This booking code is only valid until
          April 24, 2025 so please make sure to book in advance.
        </div>
      </p>
      <p className={eventDate}>Athens International Airport</p>
      <p className={eventDesc}>
        Athens International Airport is located in Athens, Greece and is by far
        the busiest in the country. Delta, British Airways, Air Berlin,
        Lufthansa, Air France, American Airlines, United, Aeroflot, and many
        more international airlines have direct flights from US cities to
        Athens. Please fill out the travel itinerary form below to provide your
        travel details.
      </p>
      <p align="center">
        <iframe
          style={{
            margin: "auto",
            display: "block",
            maxWidth: "200%",
            maxHeight: "100%",
          }}
          src="https://docs.google.com/forms/d/e/1FAIpQLSfqE_pPaCRa1t3ldNUQS9pUi75vbfSkogWMyvgZUu0IeTsyGA/viewform?embedded=true"
          width="640"
          height="1681"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </p>
      <StaticImage
        alt="vineet"
        src="../images/logo.png"
        style={{ display: "block", maxWidth: "50%", maxHeight: "50%" }}
        className={images}
      />
    </div>
  );
};
export default TravelPage;
